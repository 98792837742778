import generic from './generic'
import alexey from '../images/reviews/alexey.png'
import irina from '../images/reviews/irina.png'
import cover from '../images/covers/saint-petersburg.jpg'

export default {
  ...generic,
  slug: 'saint-petersburg',
  cover,
  collections: [
    'novinki',
    'unusual',
    'review',
    'museum',
    'bars-and-nightlife',
    'about-history-and-architecture',
    'children',
    'audio-guides',
    'free-quest-tours',
  ],
  cityNames: ['Санкт-Петербург', 'Санкт-Петербурге'],
  reviews: [
    {
      avatar: alexey,
      name: 'Алексей',
      text: 'Круто, особенно понравился людоед, сделали кучу прикольных фоток!',
      product: {
        name: 'Неформальные достопримечательности Петербурга: Площадь Востания',
        link:
          'https://surprizeme.ru/ru/saint-petersburg/unusual/neformalnye-dostoprimechatelnosti-peterburga/',
      },
    },
    {
      avatar: irina,
      name: 'Ирина',
      text:
        'Нестандартная и необычная экскурсия. Бары действительно нужно искать, но это того стоило. Было весело!',
      product: {
        name: 'Культовые бары Петербурга (18+)',
        link:
          'https://surprizeme.ru/ru/saint-petersburg/unusual/neformalnye-bary-peterburga/',
      },
    },
  ],
}
